@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Bold.ttf') format('ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts//Roboto-BoldItalic.ttf') format('ttf');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts//Roboto-Italic.ttf') format('ttf');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-Medium.ttf') format('ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts/Roboto-MediumItalic.ttf') format('ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('../fonts//Roboto-Regular.ttf') format('ttf');
  font-weight: normal;
  font-style: normal;
}
